<template>
  <main-content>
    <el-form
      :model="formData"
      :rules="rules"
      ref="ruleForm"
      class="form-wrap"
      label-width="60px"
    >
      <el-form-item label="长链接" prop="value">
        <el-input
          style="width: 500px;"
          type="textarea"
          :rows="4"
          maxlength="2000"
          v-model.trim="formData.value"
          placeholder="请输入长链接"
          :show-word-limit="true"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" @click="onSubmit">生成并复制短链</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 90%;marginTop: 48px;margin-left: 60px"
      v-if="isShowTable"
    >
      <el-table-column prop="date" label="生成时间" width="200">
      </el-table-column>
      <el-table-column prop="longUrl" label="长链接" min-width="300">
      </el-table-column>
      <el-table-column prop="shortUrl" label="短链接" min-width="300">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="handleCopyShortUrl(scope.row.shortUrl)" type="text"
            >复制短链</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </main-content>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import MainContent from '@/components/MainContent.vue';
import shortApi from '@/api/short';
import useClipboard from 'vue-clipboard3';
import dayjs from 'dayjs';
const { toClipboard } = useClipboard();

// interface TableDataType {
//   date: string;
//   longUrl: string;
//   shortUrl: string;
// }
export default defineComponent({
  components: {
    MainContent,
  },
  data() {
    const validatorValue = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入长链接！'));
      }
      if (!value.startsWith('http://') && !value.startsWith('https://')) {
        return callback(new Error('请输入以https://或http://开头的链接！'));
      }
      callback();
    };
    return {
      formData: {
        value: '',
      },
      isShowTable: false,
      tableData: [],

      rules: {
        value: [
          {
            validator: validatorValue,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    async validateValue() {
      try {
        if (!this.formData.value) {
          this.$message.warning('请输入短链接');
        }
      } catch (err) {
        console.log(err);
      }
    },

    async handleCopyShortUrl(shortUrl) {
      await toClipboard(shortUrl);
      this.$message.success('复制成功');
    },
    async onSubmit() {
      try {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            const { data } = await shortApi.create(this.formData);
            await toClipboard(data);
            this.tableData = [
              {
                date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                longUrl: this.formData.value,
                shortUrl: data,
              },
            ];
            this.isShowTable = true;
            this.$message.success('复制成功');
          }
        });
      } catch (err) {
        this.isShowTable = false;
        console.log(err);
      }
    },
  },
});
</script>
